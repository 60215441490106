import { useContext, useEffect, useState } from 'react';
import { RouteContext } from '@/context';
import { isMobile, isIOSAgent } from '@/utils/misc-utils';
import { isAndroidAgent } from '@buzzfeed/bf-utils/lib/device';

const isEligibleDevice = (type) => {
  if (type === 'ios') {
    return isIOSAgent();
  } else if (type === 'android') {
    return isAndroidAgent();
  } else if (type === 'any') {
    return true;
  }
  return false;
};

const deepLinks = {
  capsule: 'https://tasty.co/download?ct=capsule',
  cta: 'https://tasty.co/download?ct=cta',
  banner: 'https://tasty.co/download?ct=banner',
  print: 'https://tasty.co/download?ct=print',
  connatix: 'https://tasty.co/download?ct=connatix',
};

export const useDeepLink = ({
  destination,
  linkPath,
  devices = ['ios', 'android'],
  restrictSize = true
}) => {
  const [isEligible, setIsEligible] = useState(false);
  const [deepLink, setDeepLink] = useState('');
  const { queryParams } = useContext(RouteContext);

  useEffect(() => {
    if (isEligible) {
      return;
    }
    // allow a query param to force eligibility as the device checks will not work when doing dev locally
    const showOverride = queryParams && queryParams.force_branch === 'true';
    const allowedDevice = devices && Array.isArray(devices) ?
      devices.some((device) => isEligibleDevice(device)) : false;
    const allowedSize = !restrictSize || isMobile();
    if (showOverride || (allowedDevice && allowedSize)) {
      setIsEligible(true);
    }
  }, [isEligible]);

  useEffect(() => {
    if (!isEligible || !linkPath || !destination || !deepLinks[destination]) {
      return;
    }
    setDeepLink(deepLinks[destination]);
  }, [isEligible, linkPath, destination]);

  return {
    isEnabled: !!deepLink,
    deepLink: deepLink,
    trackingUrl: `tasty://${linkPath}`,
  };
};

import React, { useContext } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';

import { RouteContext } from '@/context';
import { getSchemas } from './structured-utils';

function StructuredData({ content, description, title, breadcrumbs }) {
  const { canonicalUrl, pageType, pagePath } = useContext(RouteContext);
  const schemas = getSchemas(pageType, canonicalUrl, content, description, title, pagePath, breadcrumbs);

  const scripts = [];
  Object.keys(schemas).forEach((key) => {
    scripts.push(
      <script
        type="application/ld+json"
        key={key}
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schemas[key]) }}
      />
    );
  });

  return (
    <Head>
      {scripts}
    </Head>
  );
}

StructuredData.propTypes = {
  content: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default StructuredData;

import React, { useContext } from 'react';
import Head from 'next/head';
import { RouteContext } from '@/context';

import favIcon from '@/images/icons/favicon.ico';
import {
  ABEAGLE_HOST,
  IMAGE_SERVICE_HOST,
  VIDEO_SERVICE_HOST,
  gtm_enabled
} from '@/constants';

function LinkTags({ hasVideo = false, url = null }) {
  const { canonicalUrl, pagePath, pageType, queryParams } = useContext(RouteContext);
  let fullUrl = url || `${canonicalUrl}${pagePath}`;

  if (pageType === 'recipe-buy' && queryParams.slug) {
    fullUrl = `${canonicalUrl}/recipe/${queryParams.slug}`;
  }

  return (
    <Head>
      <link rel="canonical" href={fullUrl} />
      <link rel="shortcut icon" href={favIcon} type="image/x-icon" />
      <link rel="apple-touch-icon" href={favIcon} />

      {/* dns-prefetch only */}
      <link rel="dns-prefetch" href="https://cd.connatix.com/connatix.player.js" />

      {/* preconnect with dns-prefetch fallback */}
      {/* image CDN */}
      <link rel="dns-prefetch" href={`${IMAGE_SERVICE_HOST}`} />
      <link rel="preconnect" href={`${IMAGE_SERVICE_HOST}`} crossOrigin="" />
      {/* video CDN */}
      {hasVideo &&
        <>
          <link rel="dns-prefetch" href={`${VIDEO_SERVICE_HOST}`} />
          <link rel="preconnect" href={`${VIDEO_SERVICE_HOST}`} />
        </>
      }
      {/* Google Tag Manager */}
      {gtm_enabled &&
        <>
          <link rel="preconnect" crossOrigin="true" href="https://www.googletagmanager.com" />
          <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
        </>
      }
      {/* consent platform */}
      <link rel="preconnect" crossOrigin="true" href="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" />
      {/* ABeagle tracking */}
      <link rel="dns-prefetch" href={ABEAGLE_HOST} />
      <link rel="preconnect" href={ABEAGLE_HOST} crossOrigin="" />

      {/* apstag */}
      <link rel="dns-prefetch" href="https://c.amazon-adsystem.com" />
      <link rel="preconnect" href="https://c.amazon-adsystem.com" crossOrigin="" />
    </Head>
  );
}

export default LinkTags;

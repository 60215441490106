import React, { useContext } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';

import { RouteContext } from '@/context';
import {
  FACEBOOK_APP_ID,
  FACEBOOK_PAGE_ID,
} from '@/constants';
import tastyMainImage from '@/images/tasty_main_image.png';

function MetaTags({
  deepLink,
  description,
  title,
  thumbnailUrl,
  twitterHandle,
  videoUrl,
}) {
  const { canonicalUrl, pagePath, pageType } = useContext(RouteContext);
  const fullUrl = `${canonicalUrl}${pagePath}`;

  return (
    <Head>
      {/*common meta tags - all types of pages/constant */}
      <meta charSet="utf-8" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="copyright" content="Copyright BuzzFeed, Inc. All rights reserved." />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, user-scalable=yes" />
      <meta name="referrer" content="unsafe-url" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content="Tasty" />
      <meta name="theme-color" content="#79DCF1" />

      {/* common tags, but content varies by page type and specific page contents*/}
      <meta name="description" content={description} />
      {(pageType === 'recipe-buy' || pageType === 'mycart') ? (
        <meta name="robots" content="noindex" />
      ) :
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
      }
      {/* OG meta tags */}
      <meta property="og:url" content={fullUrl} />
      <meta property="og:type" content={pageType === 'article' ? pageType : 'website'} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={['article', 'compilation', 'recipe', 'feature'].includes(pageType) ? thumbnailUrl : tastyMainImage} />
      <meta property="og:site_name" content="tasty.co" />
      <meta property="og:description" content={description} />
      {/* Facebook Tags */}
      <meta property="fb:app_id" content={`${FACEBOOK_APP_ID}`} />
      <meta property="fb:pages" content={`${FACEBOOK_PAGE_ID}`} />
      {/* Twitter tags */}
      <meta name="twitter:card" content={videoUrl ? 'player' : 'summary_large_image'} />
      <meta name="twitter:site" content="@tasty" />
      <meta name="twitter:creator" content={`@${twitterHandle || 'tasty'}`} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={['article', 'compilation', 'recipe', 'feature'].includes(pageType) ? thumbnailUrl : tastyMainImage} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={fullUrl} />
      {(['compilation', 'recipe'].includes(pageType) && videoUrl) &&
        <React.Fragment>
          <meta name="twitter:player:width" content="480" />
          <meta name="twitter:player:height" content="480" />
          <meta name="twitter:player" content={videoUrl} />
          <meta name="twitter:player:stream" content={videoUrl} />
          <meta name="twitter:player:stream:content_type" content="video/mp4" />
        </React.Fragment>
      }
      {/* deep link tags */}
      {!!deepLink.length && (
        <meta name="branch:deeplink:link" content={deepLink} />
      )}
      {/* Google Site Verification */}
      {['home', 'feature'].includes(pageType) &&
        <React.Fragment>
          {/* Google Search Console verification */}
          <meta name="google-site-verification" key="search" content="t6g5by8rYcd92pvrkQPbds9UynyzXfHHvAIX7NYzZ1A" />
          {/* Google site verification as an associate site for Goodful's youtube acct */}
          <meta name="google-site-verification" key="goodful" content="DrneJ0m_N4cmwaDipqlO0gWGK3kf-GIWfrxtVkkIqA8" />
        </React.Fragment>
      }
    </Head>
  );
}

MetaTags.defaultProps = {
  deepLink: '',
};

MetaTags.propTypes = {
  deepLink: PropTypes.string,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string,
  videoUrl: PropTypes.string,
};

export default MetaTags;

import { PAGE_TYPES_ALL, SITE_DESCRIPTION } from '@/constants';
import { convertSecondsToISO8601 } from '@/utils/misc-utils';

const pluralize = (unit, quantity, zeroIsPlural = true) => {
  let value = unit;
  if ((zeroIsPlural && quantity !== 1) || (!zeroIsPlural && quantity > 1)) {
    value += 's';
  }
  return value;
};

const getPublisher = (destination) => {
  return {
    '@type': 'Organization',
    'name': destination === 'tasty' ? 'Tasty' : 'BuzzFeed, Inc.',
    'logo': {
      '@type': 'ImageObject',
      'url': destination === 'tasty'
        ? 'https://img.buzzfeed.com/thumbnailer-prod-us-east-1/hive/binaries/471926.jpg'
        : 'https://img.buzzfeed.com/buzzfeed-static/static/images/amp/buzzfeed-60px-high.png',
      'height': 60,
      'width': destination === 'tasty' ? 117 : 341,
    },
  };
};

const getWebSite = (canonicalUrl) => ({
  '@type': 'WebSite',
  'url': canonicalUrl,
  'name': 'Tasty',
  'description': SITE_DESCRIPTION,
  'potentialAction': {
    '@type': 'SearchAction',
    'target': {
      '@type': 'EntryPoint',
      'urlTemplate': `${canonicalUrl}/search?q={search_term}`,
    },
    'query-input': {
      '@type': 'PropertyValueSpecification',
      'valueRequired': 'http://schema.org/True',
      'valueName': 'search_term',
    },
  },
});

const getWebPage = ({ canonicalUrl, pagePath, title, description }) => ({
  '@type': 'WebPage',
  'url': `${canonicalUrl}${pagePath}`,
  'name': title,
  'description': description,
  'isPartOf': getWebSite(canonicalUrl),
});

export const getSchemas = (pageType, canonicalUrl, content, description, title, pagePath = '', breadcrumbs = []) => {
  const context = 'http://schema.org';
  const publisher = getPublisher(
    content.destination_name ? content.destination_name : 'tasty'
  );
  const webpage = getWebPage({ canonicalUrl, pagePath, title, description });

  const SCHEMA_PAGE_TYPES = {
    BREADCRUMB: PAGE_TYPES_ALL,
    ITEM_LIST: ['compilation', 'feature', 'ingredient', 'ingredients', 'topic'],
    RECIPE: ['compilation', 'recipe']
  };

  const schemas = {};
  const thumbnailUrl = content.thumbnail_url
    ? `${content.thumbnail_url}${content.thumbnail_url.includes('?') ? '&' : '?'}resize=1200:*` : '';

  const publishTimestamp = content.created_at || content.published;
  const publishDate = publishTimestamp ? new Date(publishTimestamp * 1000).toISOString().substring(0, 19) : null;
  const modifiedTimestamp = content.updated_at || content.last_updated;
  const modifiedDate = modifiedTimestamp ? new Date(modifiedTimestamp * 1000).toISOString().substring(0, 19) : null;

  if (pageType === 'home') {
    schemas.website = {
      '@context': context,
      ...getWebSite(canonicalUrl),
    };
  } else if (SCHEMA_PAGE_TYPES.RECIPE.includes(pageType)) {
    // recipe & video object schemas (recipe and compilation specific)

    // recipe schema
    schemas.recipe = {
      '@context': context,
      '@type': 'Recipe',
      'dateModified': modifiedDate,
      'datePublished': publishDate,
      'description': description,
      'image': thumbnailUrl,
      'name': title,
      'publisher': publisher,
      'isPartOf': webpage,
    };
    if (content.keywords) {
      schemas.recipe.keywords = content.keywords;
    }
    if (content.credits && content.brand_id && content.credits.names) {
      schemas.recipe.author = content.credits.names.map((name) => {
        return {
          '@type': 'Brand',
          'name': name,
        };
      });
    } else if (content.credits && content.credits.names && content.credits.names.length > 0) {
      schemas.recipe.author = content.credits.names.map((name) => {
        return {
          '@type': 'Person',
          'name': name,
        };
      });
    } else {
      schemas.recipe.author = {
        '@type': 'Organization',
        'name': 'Tasty',
      };
    }

    // Add fields that only exist for actual recipes (and not compilation)
    if (pageType === 'recipe') {
      // recipeIngredient (text array of formatted ingredients)
      const ingredients = [];
      content.ingredient_sections.forEach((section) => {
        section.ingredients.forEach((ingredient) => {
          const { extra_comment, name, primary_unit } = ingredient;
          const { quantity, display } = primary_unit;
          /**
            * Note: this is basically the same text that will appear in the ingredient display, minus
            *   any metric display where appropriate
            */
          const text = `${quantity ? quantity + ' ' : ''}${quantity && display ? display + ' ' : ''}${name}${extra_comment ? (', ' + extra_comment) : ''}`;
          if (text) {
            ingredients.push(text);
          }
        });
      });

      if (content.nutrition) {
        const { calories, carbohydrates, fat, fiber, protein, sugar } = content.nutrition;

        schemas.recipe.nutrition = {
          '@type': 'NutritionInformation',
          'calories': `${calories} ${pluralize('calorie', calories)}`,
          'carbohydrateContent': `${carbohydrates} ${pluralize('gram', carbohydrates)}`,
          'fatContent': `${fat} ${pluralize('gram', fat)}`,
          'fiberContent': `${fiber} ${pluralize('gram', fiber)}`,
          'proteinContent': `${protein} ${pluralize('gram', protein)}`,
          'sugarContent': `${sugar} ${pluralize('gram', sugar)}`,
        };
      }

      schemas.recipe.recipeIngredient = ingredients;

      // recipeYield
      schemas.recipe.recipeYield = `${content.num_servings} ${content.servings_text}`;

      // recipeInstructions;
      schemas.recipe.recipeInstructions = content.instructions.map((instruction) => {
        return {
          '@type': 'HowToStep',
          'text': instruction.display_text,
        };
      });

      // aggregateRating
      if (content.ratings && content.ratings.count_total > 0) {
        // convert rating score to out of 5 for google
        const convertedRating = (content.ratings.score/20).toFixed(1);

        schemas.recipe.aggregateRating = {
          '@type': 'AggregateRating',
          'ratingValue': `${convertedRating}`,
          'ratingCount': `${content.ratings.count_total}`,
          'bestRating': '5',
          'worstRating': '0',
        };
      }

      // method, cuisine, tool, category entries
      content.seo_tags.forEach((seo_tag) => {
        schemas.recipe[seo_tag.name] = seo_tag.value;
      });

      if (content.times.cook_time.iso) {
        schemas.recipe.cookTime = content.times.cook_time.iso; // display version, only if exists
      }
      if (content.times.prep_time.iso) {
        schemas.recipe.prepTime = content.times.prep_time.iso; // display version, only if exists
      }
      if (content.times.total_time.iso) {
        schemas.recipe.totalTime = content.times.total_time.iso; // display version, only if exists
      }
    }

    // Used with both ImageObject or VideoObject, at least one of which will be added
    const sharedObjectSchema = {
      description: description,
      name: title,
      publisher: publisher,
      thumbnailUrl: thumbnailUrl,
      uploadDate: publishDate,
    };

    const hasVideo = pageType === 'compilation' || (pageType === 'recipe' && !content.non_video_recipe.status);
    if (hasVideo) {
      const formattedDuration = convertSecondsToISO8601(content.video_duration / 1000);
      const videoSchema = {
        '@type': 'VideoObject',
        'contentUrl': content.video_url,
        'duration': formattedDuration,
        ...sharedObjectSchema,
      };
      schemas.recipe.video = videoSchema;

      // video object schema
      schemas.videoObject = {
        '@context': context,
        ...videoSchema,
      };
    } else {
      // video object schema
      schemas.imageObject = {
        '@context': context,
        '@type': 'ImageObject',
        'contentUrl': thumbnailUrl,
        ...sharedObjectSchema,
      };
    }
  }

  if (pageType === 'submit-recipe') {
    schemas.webpage = {
      '@context': context,
      ...webpage,
    };
  }

  if (pageType === 'article') {
    schemas.newsArticle = {
      '@context': context,
      '@type': 'NewsArticle',
      'url': content.canonical_url,
      'articleSection': content.category,
      'dateModified': modifiedDate,
      'datePublished': publishDate,
      'inLanguage': content.language,
      'description': description,
      'headline': title,
      'thumbnailUrl': thumbnailUrl,
      'image': {
        '@type': 'ImageObject',
        'url': thumbnailUrl,
      },
      'publisher': publisher,
    };
    // only include the "isPartOf" website structure if the article is tasty canonical
    if (content.destination_name === 'tasty') {
      schemas.newsArticle.isPartOf = webpage;
    }
    schemas.newsArticle.author = content.bylines.map((byline) => {
      const articleAuthor = {
        '@type': 'Person',
        'url': `https://www.buzzfeed.com/${byline.username}`,
        'jobTitle': byline.title,
      };
      if (byline.display_name) {
        articleAuthor.name = byline.display_name;
      }
      return articleAuthor;
    });
  }

  if (SCHEMA_PAGE_TYPES.ITEM_LIST.includes(pageType)) {
    const items = content.items || content.recipes.items;
    schemas.itemList = {
      '@context': context,
      '@type': 'ItemList',
      'mainEntityOfPage': {
        '@type': 'CollectionPage',
        '@id': `${canonicalUrl}${pagePath}`,
      },
      'isPartOf': webpage,
      'itemListElement': items.map((item, index) => {
        const type = item.type || 'recipe';
        return {
          '@type': 'ListItem',
          'position': index + 1,
          'url': `${canonicalUrl}/${type}/${item.slug}`,
        };
      }),
    };
  }

  schemas.breadcrumbLists = [];

  if (['recipe', 'tag'].includes(pageType) && breadcrumbs.length > 0) {
    schemas.breadcrumbLists.push({
      '@context': context,
      '@type': 'BreadcrumbList',
      'itemListElement': breadcrumbs.map((bc, i) => ({
        '@type': 'ListItem',
        'position': i + 1,
        'name': bc.name,
        'item': `${canonicalUrl}${bc.path}`,
      }))
    });
  }

  if (SCHEMA_PAGE_TYPES.BREADCRUMB.includes(pageType)) {
    schemas.breadcrumbLists.push({
      '@context': context,
      '@type': 'BreadcrumbList',
      'itemListElement': [
        {
          '@type': 'ListItem',
          'position': 1,
          'name': 'Home',
          'item': canonicalUrl,
        },
        {
          '@type': 'ListItem',
          'position': 2,
          'name': title,
          'url': `${canonicalUrl}${pagePath}`,
        },
      ],
    });
  }

  if (schemas.breadcrumbLists.length == 0) {
    delete schemas.breadcrumbLists;
  }

  return schemas;
};
